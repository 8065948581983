/* App.css */

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  background-color: #333;
  color: white;
  font-family: Arial, sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 800px;
  margin: auto;
  background-color: #222;
  border: 3px solid #555;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.App-header p {
  margin: 0;
  font-size: 2em;
  color: #AAA;  /* Updated to a lighter grey */
}

.AudioProcessor {
  margin-top: 20px;
}

label {
  display: block;
  margin: 20px 0;
}

select, 
input[type="range"],
input[type="checkbox"] {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
}

input[type="range"] {
  background-color: #555;  /* Updated to a darker grey */
}

input[type="checkbox"] {
  width: auto;
}

button {
  padding: 10px 20px;
  background-color: #555;  /* Updated to a darker grey */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #444;  /* Darkened grey for hover effect */
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
}

nav li.title {
  font-size: 2em;
  flex-grow: 2;
  text-align: center;
}

nav li.about-link {
  position: absolute;
  right: 0;
  padding-right: 20px;
}

nav li {
  display: inline;
  margin-right: 20px;
}

nav a {
  color: #AAA;  /* Updated to a lighter grey */
  text-decoration: none;
  font-weight: bold;
}

nav a:hover {
  color: #888;  /* Darkened grey for hover effect */
}

.audio-warning {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #CE1141;
  background-color: #13274F;
  color: #CE1141;
  border-radius: 5px;
  text-align: center;
}
